<template>
  <div class="package-rules">
    <div class="header">
      <div>
        <van-icon name="arrow-left" class="fs24 c-fff" @click="goBackFn" />
      </div>
      <div class="fs18 c-fff">使用规则</div>
      <div></div>
    </div>
    <div class="content">
      <div class="content-item mt0">
        <h2>饿了么吃货月卡</h2>
        <div class="detail">
          <p>商品面值：15元</p>
          <p>类型说明:</p>
          <p>
            1.若用户是会员籍，则根据用户当前的超级会员类型延期31天，目前的超级会员类型有以下几种：红包版，奖励金版，轻享版；
          </p>
          <p>2.若用户不是在籍会员，则改为发红包版超级会员月卡，会籍期31天;</p>
          <p>3.仅支持手机号充值，请确定充值账号的正确性；虚拟商品，无法退货。</p>
          <p>权益说明：</p>
          <p>饿了么超级吃货卡含5大权益</p>
          <p>1.专享红包：每月20元无门槛红包 (4张5元);</p>
          <p>
            2.下单得5倍吃货豆：每完成1笔在线支付实付满20元(轻享版15元)的订单，即可获得100个;
          </p>
          <p>3.超级吃货红包可升级¥6-11大额商家红包；</p>
          <p>4.购买红包加量包，专享价5折起;</p>
          <p>5.超级吃货专享特价商品，低至1折。</p>
        </div>
      </div>

      <div class="content-item mt20">
        <h2>芒果TV视频会员月卡</h2>
        <div class="detail">
          <p>商品面值：20元</p>
          <p>
            充值账号：仅支持手机号充值，需要充值邮箱的，请先确定您的芒果TV账号绑定了邮箱。第三方账号（如QQ、微信、微博）不支持直接充值，需登录芒果APP个人中心绑定手机后再充值。充值成功后，请进入芒果TV--会员--登录--绑定手机号--查询会员信息。
          </p>
          <p>适用平台：电脑、平板、手机 （不支持电视）</p>
          <p>福利券：每月赠送2张观影券（即买即送）</p>
          <p>兑换说明：请确定充值账号的正确性；虚拟商品，无法退货。</p>
        </div>
      </div>

      <div class="content-item mt20">
        <h2>滴滴青桔单车全国通用畅骑卡-7天周卡</h2>
        <div class="detail">
          <p>商品面值：10元</p>
          <p>
            1.
            购买/兑换前请确认所在城市是否有单车服务（可登陆滴滴出行app/滴滴青桔app或小程序查看确认）
          </p>
          <p>
            2.
            本卡仅限单车骑行使用，不能用于电单车、电动车，一经充值/兑出，不退不换，遗失不补；
          </p>
          <p>
            3.单车骑行卡仅可抵扣正常骑行费用，不可抵扣调度管理费，车辆管理费等。单车骑行卡在订单结算时是否可用，以落锁时骑行卡是否在有效期为准；
          </p>
          <p>
            4.
            如多次购买核销规则相同畅骑卡（e.g.多张月卡），有效天数自动累加，总有效天数超出360天则不可继续购买。若当前无骑行卡，用户购买或领取单车骑行卡后，对应骑行卡的优惠权益立即生效；
          </p>
          <p>
            5.
            购买多张骑行卡或者多种骑行卡，生效规则为依次生效，即第一张骑行卡到期失效后，第二张骑行卡才会生效如账户中已有app购买的城市卡，又充值了全国畅骑卡，则需要等到先充值的-卡种失效后，后续卡种才会生效；
          </p>
          <p>
            6.若用户同时有多张单车骑行卡在有效期内，结算时优惠权益不可登加，默认为用户使用最优权益的骑行卡.
          </p>
        </div>
      </div>

      <div class="content-item mt20">
        <h2>京东E卡5元面值</h2>
        <div class="detail">
          <p>商品面值：5元</p>
          <p>兑换说明：</p>
          <p>1.启动手机京东app，输入账号密码进行登录。</p>
          <p>2.点击主界面右下侧“我的”切换标签页，然后选择“我的钱包”。</p>
          <p>
            3.显示当前绑定的e卡余额，绑定会有个有效期，过了期就不能够使用了，点击“绑定新卡”。
          </p>
          <p>4.输入京东E卡卡密并确定，绑定成功后自动返回到上一个页面。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent, getCurrentInstance } from "vue";
import { ua } from "@/utils/ua";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const app = getCurrentInstance();
    const $router = useRouter();
    const state = reactive({
      count: 0,
    });
    const goBackFn = () => {
      if (ua.isAndroidApp || ua.isIosApp) {
        app.proxy.$bridge.handleMethods("goBack");
      } else {
        $router.go(-1);
      }
    };
    return {
      goBackFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.package-rules {
  width: 100%;
  min-height: 100%;
  height: auto;
  background: linear-gradient(164deg, #feab03 0%, #fc7501 100%);
  background-size: 100% 100%;
  .header {
    display: flex;
    padding: 10px;
    justify-content: space-between;
  }
  .content {
    width: 335px;
    height: 100%;
    background: #fff;
    margin: 0px 20px;
    border-radius: 4px;
    padding: 10px;
    .content-item {
      .detail {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}
</style>
